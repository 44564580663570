<template>
    <v-container fluid>
        <v-skeleton-loader type="table-heading" v-if="loading"></v-skeleton-loader>
        <v-text-field v-if="create_quick_task" v-model="new_task.name" ref="quickTask" @blur="closeCreateQuickTask()"
            @keyup.enter="createTask()" dense hide-details></v-text-field>
        <v-btn @click="openCreateQuickTask()" v-if="create_quick_task == false && !loading" text>
            <v-icon class="mr-2">mdi-plus</v-icon>
            Add Task
        </v-btn>
        <v-data-table v-if="!loading" :headers="task_headers" :items="tasks">
            <template #body="props">
                <draggable :list="props.items" item-key="h_id" tag="tbody" v-bind="dragOptions" @start="drag = true"
                    @end="drag = false" @change="updateTaskOrder(tasks)">
                    <tr v-for="(item, index) in props.items" :key="item.id" @click="openTaskDetailsDialog(item)"
                        style="background-color:transparent;">
                        <td>
                            <div style="display:flex;align-items:center;" class="tooltip">
                                <v-text-field v-model="item.name" readonly dense hide-details class="hide-border"
                                    style="font-weight:bold;"></v-text-field>
                                <span class="tooltiptext">
                                    <label>
                                        <b>{{ item.name }}</b>
                                    </label>
                                </span>
                            </div>
                        </td>
                        <td>
                            <v-select v-model="item.company_id" :items="[{ name: 'None', id: null }, ...get_companies]"
                                @change="updateTask(item)" @click.stop item-text="name" item-value="id" dense
                                hide-details class="hide-border" append-icon="">
                                <template #selection="{ item }">
                                    <v-chip style="color:black;width:90%;cursor: pointer;background-color:transparent;">
                                        <label v-if="item.id" style="font-size:12px;cursor:pointer;">
                                            {{ item.name }}
                                        </label>
                                        <label v-else style="font-size:12px;color:grey;cursor:pointer;">
                                            {{ item.name }}
                                        </label>
                                    </v-chip>
                                </template>
                            </v-select>
                        </td>
                        <td>
                            <v-select v-model="item.project_id" :items="[
                                { name: 'None', id: null },
                                ...projects.filter(x => x.company_id == item.company_id)
                            ]" @change="updateTask(item)" @click.stop item-text="name" item-value="id" dense
                                hide-details class="hide-border" append-icon="">
                                <template #selection="{ item }">
                                    <v-chip style="color:black;width:90%;cursor: pointer;background-color:transparent;">
                                        <label v-if="item.id" style="font-size:12px;cursor:pointer;">
                                            {{ item.name }}
                                        </label>
                                        <label v-else style="font-size:12px;color:grey;cursor:pointer;">
                                            {{ item.name }}
                                        </label>
                                    </v-chip>
                                </template>
                            </v-select>
                        </td>
                        <td>
                            <v-combobox v-model="item.tags" :items="tags" @change="updateTaskTags(item)"
                                @click.native.stop multiple item-text="name.en" item-value="name.en"
                                :return-object="false" dense hide-details class="hide-border" append-icon=""
                                placeholder="None">
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-title>
                                            Press <kbd>enter</kbd> to create a new tag
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-combobox>
                        </td>
                        <td>
                            <v-select v-model="item.assignee_id" :items="users" @change="updateTask(item)" @click.stop
                                item-text="name" item-value="id" dense hide-details class="hide-border" append-icon="">
                                <template #selection="{ item }">
                                    <v-chip style="color:black;width:90%;cursor: pointer;background-color:transparent;">
                                        <label style="font-size:12px;cursor:pointer;">{{ item.name }}</label>
                                    </v-chip>
                                </template>
                            </v-select>
                        </td>
                        <td>
                            <v-menu v-model="due_date_menu[index]" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip @click.stop v-bind="attrs" v-on="on"
                                        style="color:black;width:90%;cursor: pointer;background-color:transparent;">
                                        <label v-if="item.due_date" style="font-size:12px;cursor:pointer;">
                                            {{ item.due_date }}
                                        </label>
                                        <label v-else style="font-size:12px;cursor:pointer;color:grey;">
                                            None
                                        </label>
                                    </v-chip>
                                </template>
                                <v-date-picker v-model="item.due_date"
                                    @input="due_date_menu[index] = false; updateTask(item)"></v-date-picker>
                            </v-menu>
                        </td>
                        <td>
                            <v-btn @click.stop text>
                                <vue-timepicker v-model="item.allocated_time" @close="updateTask(item)" format="HH:mm"
                                    :minute-interval="5" input-width="70px" hide-clear-button></vue-timepicker>
                            </v-btn>
                        </td>
                        <td>
                            <v-select v-model="item.priority_id" @change="updateTask(item)" @click.stop
                                :items="[{ name: 'None', id: null }, ...get_priorities]" item-text="name"
                                item-value="id" dense hide-details class="hide-border" append-icon="">
                                <template #selection="{ item }">
                                    <v-chip style="color:white;width:90%;cursor: pointer;justify-content:center;"
                                        :color="item.color">
                                        <strong style="font-size:12px;">{{ item.name }}</strong>
                                    </v-chip>
                                </template>
                            </v-select>
                        </td>
                        <td>
                            <v-select v-model="item.status_id" @change="updateTask(item)" @click.stop
                                :items="[{ name: 'None', id: null }, ...get_statuses]" item-text="name" item-value="id"
                                dense hide-details class="hide-border" append-icon="">
                                <template #selection="{ item }">
                                    <v-chip style="color:white;width:90%;cursor: pointer;justify-content:center;"
                                        :color="item.color">
                                        <strong style="font-size:12px;">{{ item.name }}</strong>
                                    </v-chip>
                                </template>
                            </v-select>
                        </td>
                        <td>
                            {{ $date(item.created_at).format('M/DD/YYYY') }}
                            <br>
                            Week {{ $date(item.created_at).week() }}
                        </td>
                        <td>
                            <v-menu right bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn @click.stop small dense icon v-bind="attrs" v-on="on">
                                        <v-icon color="black">mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="openDeleteTaskDialog(item)">
                                        <v-list-item-title>
                                            Delete
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </td>
                    </tr>
                </draggable>
            </template>
        </v-data-table>

        <TaskNavigationComponent :task_details_dialog="task_details_dialog"
            :can_close_task_details_dialog="can_close_task_details_dialog" :users="users" @updateTask="updateTask"
            @closeTaskDetailsDialog="closeTaskDetailsDialog" @getTasks="getTasks" />

        <v-dialog v-model="delete_task_dialog" persistent max-width="500">
            <v-card>
                <v-card-title>Are you sure you want to delete this task?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDeleteTaskDialog()" text>Cancel</v-btn>
                    <v-btn @click="deleteTask()" color="error">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import TaskNavigationComponent from './TaskNavigationComponent.vue'
import draggable from 'vuedraggable'
export default {
    name: 'TaskTableComponent',
    components: {
        TaskNavigationComponent, draggable
    },
    props: [
        'users', 'task_type', 'tasks', 'selected_assignee', 'project_id', 'meeting_id', 'loading', 'tags'
    ],
    data: () => ({
        app_url: null,
        api_url: null,
        task_headers: [
            { text: 'Task Name', value: 'name', width: '28%', sortable: false },
            { text: 'Company', value: 'company_id', width: '8%', sortable: false },
            { text: 'Project', value: 'project_id', width: '8%', sortable: false },
            { text: 'Tags', value: 'project_id', width: '8%', sortable: false },
            { text: 'Assignee', value: 'assignee_id', width: '10%', sortable: false },
            { text: 'Due Date', value: 'due_date', width: '6%', sortable: false },
            { text: 'Allocated Time', value: 'allocated_time', width: '6%', sortable: false },
            { text: 'Priority', value: 'priority_id', width: '8%', sortable: false },
            { text: 'Status', value: 'status_id', width: '8%', sortable: false },
            { text: 'Created', value: 'name', width: '5%', sortable: false },
            { text: '', width: '1%', sortable: false },
        ],
        due_date_menu: [],
        new_task: {
            name: null,
            assignee_id: null,
            description: null,
            priority_id: null,
            due_date: null,
        },
        task_details_dialog: false,
        selected_task: null,
        can_close_task_details_dialog: false,
        original_selected_task: {},
        create_quick_task: false,
        delete_task_dialog: false,
        task_to_delete: null,
        drag: false,
        dragOptions: {
            animation: 200,
            disabled: false,
            ghostClass: 'ghost',
        },
        projects: [],
    }),
    created() {
        this.app_url = process.env.VUE_APP_URL
        this.api_url = process.env.VUE_APP_API_URL
    },
    mounted() {
        this.getProjects()
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'companies/get_selected_company',
            get_companies: 'companies/get_companies',
            get_priorities: 'tasks/get_priorities',
            get_statuses: 'tasks/get_statuses',
            get_selected_priorities: 'tasks/get_selected_priorities',
            get_selected_statuses: 'tasks/get_selected_statuses',
            get_selected_task: 'tasks/get_selected_task',
        }),
        selected_priorities: {
            get() {
                return this.get_selected_priorities
            },
            set(new_priorities) {
                this.$store.dispatch('tasks/set_selected_priorities', new_priorities)
            }
        },
        selected_statuses: {
            get() {
                return this.get_selected_statuses
            },
            set(new_statuses) {
                this.$store.dispatch('tasks/set_selected_statuses', new_statuses)
            }
        },
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        getTasks() {
            this.$emit('getTasks')
        },
        async updateTaskOrder(items) {
            this.$nextTick(() => {
                items.forEach((item, index) => {
                    item.h_id = index
                })
                this.$axios.post('api/tasks/update_task_order', items)
            })
        },
        async getProjects() {
            let payload = {
                company_id: this.get_selected_company.id,
            }
            this.$axios.get('api/projects/get_projects', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.projects = data.data
                    }
                })
        },
        async getTags() {
            this.$emit('getTags')
        },
        openCreateQuickTask() {
            this.create_quick_task = true
            this.new_task.assignee_id = this.get_user.id
            if (this.task_type == 'assigned' && this.selected_assignee.id > 0) {
                this.new_task.assignee_id = this.selected_assignee.id
            }
            this.$nextTick(() => {
                setTimeout(() => {
                    this.$refs.quickTask.$refs.input.focus()
                })
            })
        },
        closeCreateQuickTask() {
            this.create_quick_task = false
            this.new_task = {
                name: null,
                description: null,
                priority_id: null,
                due_date: null,
            }
        },
        async createTask() {
            let payload = {
                company_id: (this.get_selected_company) ? this.get_selected_company.id : null,
                project_id: this.project_id,
                meeting_id: this.meeting_id,
                ...this.new_task
            }
            await this.$axios.post('api/tasks/create_task', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.new_task.name = null
                        this.getTasks()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        async updateTask(item) {
            let payload = {
                company_id: item.company_id,
                assignee_id: item.assignee_id,
                project_id: item.project_id,
                name: item.name,
                status_id: item.status_id,
                description: item.description,
                priority_id: item.priority_id,
                due_date: item.due_date,
                allocated_time: item.allocated_time,
            }
            await this.$axios.post(`api/tasks/update_task/${item.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getTasks()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        async updateTaskTags(item) {
            let payload = {
                company_id: item.company_id,
                assignee_id: item.assignee_id,
                project_id: item.project_id,
                tags: item.tags,
            }
            await this.$axios.post(`api/tasks/update_task/${item.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getTasks()
                        this.getTags()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openTaskDetailsDialog(item) {
            this.task_details_dialog = true
            this.$store.dispatch('tasks/set_selected_task', item)
            this.original_selected_task = item
            setTimeout(() => {
                this.can_close_task_details_dialog = true
            }, 100)
        },
        closeTaskDetailsDialog() {
            this.task_details_dialog = false
            let selected_task = {
                name: this.get_selected_task.name,
                company_id: this.get_selected_task.company_id,
                project_id: this.get_selected_task.project_id,
                priority_id: this.get_selected_task.priority_id,
                status_id: this.get_selected_task.status_id,
                due_date: this.get_selected_task.due_date,
                // allocated_time: this.get_selected_task.allocated_time,
            }
            let original_task = {
                name: this.original_selected_task.name,
                company_id: this.original_selected_task.company_id,
                project_id: this.original_selected_task.project_id,
                priority_id: this.original_selected_task.priority_id,
                status_id: this.original_selected_task.status_id,
                due_date: this.original_selected_task.due_date,
                // allocated_time: this.original_selected_task.allocated_time,
            }
            console.log(JSON.stringify(selected_task))
            console.log(JSON.stringify(original_task))
            if (JSON.stringify(selected_task) != JSON.stringify(original_task)) {
                console.log('yes')
                this.updateTask(this.get_selected_task)
            }
            this.$store.dispatch('tasks/set_selected_task', {})
            this.original_selected_task = {}
        },
        openDeleteTaskDialog(item) {
            this.delete_task_dialog = true
            this.task_to_delete = item
        },
        closeDeleteTaskDialog() {
            this.delete_task_dialog = false
            this.task_to_delete = null
        },
        async deleteTask() {
            await this.$axios.delete(`api/tasks/delete_task/${this.task_to_delete.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteTaskDialog()
                        this.getTasks()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
    }
}
</script>

<style lang="scss" scoped>
:deep(.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr) {
    height: 50px;
}

:deep(td) {
    border-bottom: solid 1px lightgrey;
}

:deep(.v-input__slot::before) {
    border-color: rgb(221, 221, 221) !important;
}

:deep(.hide-border>.v-input__control>.v-input__slot:before, .hide-border>.v-input__control>.v-input__slot:after) {
    border: none !important;
}

:deep(.toggle-am-pm) {
    display: none;
}

:deep(.v-text-field.v-input--dense:not(.v-text-field--outlined) input) {
    cursor: pointer;
}

:deep(.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-label) {
    cursor: pointer;
}

:deep(.vue__time-picker input.display-time) {
    border: none !important;
}

:deep(.vue__time-picker input.display-time),
:deep(input),
:deep(.v-select__slot),
:deep(td) {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}

:deep(.select-list) {
    position: fixed;
}

:deep(::-webkit-scrollbar) {
    width: 10px;
}

:deep(::-webkit-scrollbar-thumb) {
    border-radius: 2px;
    /* background-color: rgba(0,0,0,.5); */
    background-color: #a5a5a5;
    color: #a5a5a5;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 290px;
    max-height: 200px;
    background-color: #191C20;
    color: white;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 10;
    bottom: 100%;
    left: 50%;
    margin-left: -100px;
    /* Use half of the width (120/2 = 60), to center the tooltip */
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}
</style>